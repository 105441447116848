
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css"
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { PHProvider } from './providers';
import Banner from './components/Banner';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
    <Router>
    <PHProvider>
        <Routes>
            <Route path="/*" element={<App />} />
        </Routes>
        <Banner />
    </PHProvider>
    </Router>
    </React.StrictMode>
);
