// app/banner.js
"use client";
import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { set } from "mongoose";

export default function Banner() {
  const posthog = usePostHog();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (
      !(posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing())
    ) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) {
    return null;
  }

  const acceptCookies = () => {
    posthog.opt_in_capturing(); // new
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing(); // new
    setShowBanner(false);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white text-gray-800 font-bebas-kai gap-4 p-6 flex flex-col justify-between items-center z-40">
      <h2 className="text-2xl font-bold font-bebas-kai">
        About cookies on this site
      </h2>
      <p className="text-center">
        This site uses cookies to collect data on how you use the site, so that
        we can improve your experience. Please select one of the options below
        to tell us your cookie preferences.
      </p>
      <div className="flex gap-4">
        <button
          type="button"
          onClick={acceptCookies}
          className=" bg-primary-green hover:bg-green-700 text-white tracking-widest font-bold py-2 px-4 rounded"
        >
          Accept cookies
        </button>
        <span> </span>
        <button
          type="button"
          onClick={declineCookies}
          className="bg-primary-orange hover:bg-orange-700 text-white tracking-widest font-bold py-2 px-4 rounded"
        >
          Decline cookies
        </button>
      </div>
    </div>
  );
}
